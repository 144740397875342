import React, { useContext, useState, useEffect } from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import Context from 'reactContext'
import Image from 'components/Image'
import GridButtons from 'components/GridButtons'
import Nav from 'components/nav'
import Hero from 'components/hero'
import { get } from 'utils/request'
import { UserType } from 'consts'

const IndexPage = () => {
  const [visible, setVisible] = useState(false)
  const { settingsMerge } = useContext(Context)

  const iconsInfography = (image, slogan) => {
    return (
      <div className="infography-style">
        <div className="infography-icon">{image}</div>
        <h4 className="text-base mt-6">{slogan}</h4>
      </div>
    )
  }

  const contentHeader = (slogan, subSlogan) => (
    <div className="text-center" key={slogan}>
      <p className="mb-3 index-title">{slogan}</p>
      <p className="index_letters index_content_subslogan">{subSlogan}</p>
    </div>
  )

  useEffect(() => {
    if (localStorage.getItem('USER_TYPE') === UserType.user) {
      get('users/download_link').then((res) => {
        settingsMerge(res)
      })
    }
    if (localStorage.getItem('USER_TYPE') === UserType.manager) {
      get('managers/initial_data').then((res) => {
        settingsMerge(res.settings)
      })
    }
    if (localStorage.getItem('USER_TYPE') === UserType.pendingManager) {
      setVisible(true)
    }
  }, [])

  return (
    <Nav>
      <Hero />
      <div className="index-content">
        {contentHeader('极致的体验', '使用简单')}
        <Grid container className="text-center mt-12" spacing={2}>
          <Grid item xs>
            {iconsInfography(<Image fileName="fast_on.png" alt="" className="w-24 m-auto" />, '极快速')}
          </Grid>
          <Grid item xs>
            {iconsInfography(<Image fileName="simple_on.png" alt="" className="w-24 m-auto" />, '极简单')}
          </Grid>
          <Grid item xs>
            {iconsInfography(<Image fileName="safe_on.png" alt="" className="w-24 m-auto" />, '极安全')}
          </Grid>
        </Grid>
      </div>
      <div className="index-content">
        {contentHeader('多平台支持', '一个账号，多个平台')}
        <div className="mt-16">
          <GridButtons />
        </div>
      </div>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogContent>您的企业通申请正在审核中，请耐心等待哦！</DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </Nav>
  )
}

export default IndexPage
