import React, { useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import Modal from '@material-ui/core/Modal'
import size from 'lodash/size'
import Context from 'reactContext'
import { isAndroid, isWechat } from 'utils'

const GridButtons = () => {
  const { settings } = useContext(Context)
  const [visible, setVisible] = useState(false)
  const notification = settings.get('notification')
  return (
    <Grid container>
      {[
        ['Windows', 'windows'],
        ['Mac', 'mac'],
        ['Android', 'android'],
        ['iOS', 'ios'],
        ['Ubuntu', 'linux'],
      ].map((v) => (
        <Grid item key={v[0]} xs={6} md className="text-center my-6">
          <a
            href={isAndroid() && isWechat() ? null : size(notification) > 0 ? notification[`${v[1]}_download_link`] : null}
            onClick={isAndroid() && isWechat() ? () => setVisible(true) : v[0] === 'iOS' ? () => navigate('/ios') : null}
          >
            <i className={`iconfont icon-${v[0].toLowerCase()} text-5xl text-blue-600 cursor-pointer`} />
          </a>
          <div className="mt-3 text-center text-xs">{v[0]}</div>
        </Grid>
      ))}
      <Modal open={visible} className="absolute top-0" onClose={() => setVisible(false)}>
        <div className="wechat-img" />
      </Modal>
    </Grid>
  )
}

export default GridButtons
