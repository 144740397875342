import React from 'react'
import { Container } from '@material-ui/core'
import Image from 'components/Image'

export default () => (
  <section id="hero" className="pt-24 bg-gray-500 overflow-hidden">
    <Container maxWidth="lg">
      <div className="text-center py-12 m-0 text-4xl text-white font-bold">
        <h1>企业通</h1>
      </div>
      <Image fileName="qiyetong-bg.png" alt="" className="animate__fadeInUp animate__animated" />
    </Container>
  </section>
)
